.headerContainer {
  width: 100%;
  border-bottom: 3px solid rgba(26, 21, 21, 0.744);
  outline: 1px solid rgb(20, 16, 16, 0.7);
}

.background {
  width: 100%;
  visibility: visible;
}

.backgroundHidden {
  width: 70%;
  visibility: hidden;
}

.logo {
  width: 39%;
  z-index: 3;
  position: absolute;
  top: 3px;
}

.logo2 {
  width: 29%;
  z-index: 3;
  position: absolute;
  top: 3px;
  left: 10px;
}

.navContainer {
  position: relative;
  bottom: 40px;
  border: 1px solid white;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  z-index: 7;
}

.navContainer2 {
  position: relative;
  bottom: 12px;
  border: 1px solid white;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  z-index: 7;
}

.menuButton {
  flex: 1;
  font-size: medium;
  color: white;
  background: none;
  padding: 8px 0px;
}

.menuButton:hover {
  cursor: pointer;
}

@media (max-width: 900px) {
  .menuButton {
    padding: 2px 0px;
  }
  .navContainer {
    bottom: 30px;
  }
}

@media (max-width: 600px) {
  .menuButton {
    font-size: small;
  }
  .logo {
    width: 36%;
  }
  .navContainer2 {
    bottom: 6px;
  }
}

@media (max-width: 450px) {
  .menuButton {
    font-size: x-small;
  }
  .navContainer {
    bottom: 20px;
  }
  .navContainer2 {
    bottom: 7px;
  }
  .backgroundHidden {
    width: 77%;
  }
}

@media (max-width: 250px) {
  .menuButton {
    font-size: xx-small;
  }
}
