li {
  flex: 1;
  margin-top: 6px;
  margin-bottom: 6px;
}

.descriptionContainer {
  width: 100%;
  top: 800px;
  background-color: #191c23;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  text-align: justify;
  line-height: 1.6;
}

.title {
  font-family: "Abel", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #f15d3e;
  font-size: 23px;
  padding: 20px 60px;
  flex: 1;
}

.mainDescription {
  width: 67%;
}

.mainDescriptionHeading {
  width: 32%;
  text-align: start;
}

.descriptionText {
  padding: 0px 60px 40px;
}

.card {
  flex: 1;
  background-color: #24272e;
  margin: 20px;
  padding: 20px;
  font-family: "Abel", sans-serif;
  text-align: start;
}

.cardTitleContainer {
  font-weight: bold;
  font-style: normal;
  font-size: 17px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
}

.cardTitle {
  font-family: "Abel", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #f15d3e;
  font-size: 23px;
  flex: 1;
}

.serviceList {
  max-height: fit-content;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: stretch;
}

.icon {
  margin: 0px 10px;
  color: #f15d3e;
  width: fit-content;
}

.largeIcon {
  margin: 0px 10px;
  width: 70px;
  height: 100px;
}

.titleText {
  width: 80%;
}

.cardDescriptionText {
  padding: 0px 20px 40px;
}

@media (max-width: 1020px) {
  .serviceList {
    font-size: small;
  }
  .card {
    margin: 20px 200px;
  }
}

@media (max-width: 900px) {
  .card {
    margin: 20px 150px;
  }
}

@media (max-width: 800px) {
  .mainDescription {
    width: 100%;
  }
  .mainDescriptionHeading {
    width: 100%;
  }
}

@media (max-width: 700px) {
  .title {
    font-size: medium;
  }
  .descriptionText {
    font-size: small;
  }
  .card {
    margin: 20px 100px;
  }
}

@media (max-width: 540px) {
  .card {
    margin: 20px 70px;
  }
}

@media (max-width: 400px) {
  .card {
    margin: 20px 50px;
  }
}

@media (max-width: 300px) {
  .card {
    margin: 20px 20px;
  }
}
