body {
  margin: 0;
  font-family: "Rubik", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #191c23;
  color: #fafffd;
  font-weight: 300;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  font-family: inherit;
  color: white;
  text-underline-offset: 4px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Abel", sans-serif;
  font-weight: 400;
  font-style: normal;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #090c22;
}

::-webkit-scrollbar-thumb {
  background: #212a59;
}

::-webkit-scrollbar-thumb:hover {
  background: #464f7c;
}

::-webkit-scrollbar-button {
  background: #213659;
}

::-webkit-scrollbar-button:hover {
  background: #464f7c;
}

::-webkit-scrollbar-corner {
  background: #090c22;
}

table,
th,
td {
  font-size: small;
  border: 1px solid white;
  padding: 3px;
  cursor: pointer;
}
